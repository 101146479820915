import React, { useState, useReducer } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Layout from "../components/layout"
import Editor from "../components/editor"

const initialForm = {
  isFormValid: false,
  articleForm: {
    title: {
      placeholder: "Ajouter le titre d'article",
      touched: false,
      inputtype: "input",
      type: "text",
      value: "",
      isValid: false,
      validation: {
        required: true,
      },
    },
    imgUrl: {
      placeholder: "Ajouter l'image d'article",
      touched: false,
      inputtype: "input",
      type: "text",
      value: "",
      isValid: false,
      validation: {
        required: true,
      },
    },
    body: {
      placeholder: "Ajouter une description d'article",
      touched: false,
      inputtype: "editor",
      type: "text",
      value: "",
      isValid: false,
      validation: {
        required: true,
        different: "<p><br></p>",
      },
    },
  },
}

const initialHttp = {
  formSent: false,
  formSubmitted: false,
}

const reducer = (state, action) => {
  const { type, inputIdentifier, value } = action
  switch (type) {
    case "updateInput":
      const articleFormUpdated = { ...state.articleForm }
      const inputIdentifierUpdated = { ...articleFormUpdated[inputIdentifier] }
      inputIdentifierUpdated.value = value
      inputIdentifierUpdated.isValid = isValid(
        value,
        inputIdentifierUpdated.validation
      )
      inputIdentifierUpdated.touched = true
      articleFormUpdated[inputIdentifier] = inputIdentifierUpdated
      return {
        articleForm: articleFormUpdated,
        isFormValid: _isFormValid(articleFormUpdated),
      }
    default:
      throw new Error()
  }
}

const httpReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case "formSubmitted":
      return {
        ...state,
        formSubmitted: true,
      }
    case "formSent":
      return {
        ...state,
        formSent: true,
      }
    case "formSendFailed":
      return {
        ...state,
        formSent: false,
      }
    default:
      throw new Error()
  }
}

const isValid = (value, rules) => {
  let valid = true
  if (rules.required) valid = valid && value.trim() !== ""
  if (rules.minLength) valid = valid && value.length >= rules.minLength
  if (rules.regularExpression)
    valid = valid && rules.regularExpression.test(value)
  if (rules.different) {
    valid = valid && rules.different != value
  }
  return valid
}

const _isFormValid = form => {
  let isFormValid = true
  for (let key in form) {
    isFormValid = isFormValid && form[key].isValid
  }

  return isFormValid
}

const AddArticle = () => {
  let token;
  if (typeof window !== 'undefined') {
    /* 
      At build phase we dont have access to localStorage
      because we are doing server side rendering (no browser)
    */
    token = localStorage.getItem("token")
  }
  if (!token && typeof window !== 'undefined') {
    navigate("/login")
  }
  const [formState, dispatch] = useReducer(reducer, initialForm)
  const [httpState, dispatchHttp] = useReducer(httpReducer, initialHttp)
  const { sendError } = httpState
  const { formSent } = httpState
  const { articleForm } = formState
  const apiEndPoint = "https://smartop-back-preprod.azurewebsites.net/api/"

  const getFields = articleForm => {
    let fields = []
    for (let key in articleForm) {
      const fieldId = articleForm[key]
      const { inputtype, placeholder, value, touched, isValid } = fieldId
      const { formSubmitted } = httpState
      let error = null
      if (!isValid && (touched || formSubmitted)) {
        error = <p className="error">Le champs est invalide</p>
      }
      if (inputtype === "input") {
        fields.push(
          <div className="u-margin-bottom-small">
            <input
              value={value}
              placeholder={placeholder}
              type="text"
              class="input w-input"
              onChange={event => inputChangedHandler(event.target.value, key)}
            />
            {error}
          </div>
        )
      } else {
        fields.push(
          <div className="u-margin-bottom-small">
            <Editor
              placeholder={placeholder}
              value={value}
              inputChanged={value => inputChangedHandler(value, key)}
            />
            {error}
          </div>
        )
      }
    }
    return fields
  }

  const inputChangedHandler = (value, inputIdentifier) => {
    dispatch({
      type: "updateInput",
      value: value,
      inputIdentifier,
    })
  }

  const getBody = articleForm => {
    let body = {}
    for (let key in articleForm) {
      body = {
        ...body,
        [key]: articleForm[key].value,
      }
    }
    return body
  }

  const formSubmittedHandler = e => {
    dispatchHttp({ type: "formSubmitted" })
    e.preventDefault()
    const { isFormValid, articleForm } = formState
    if (isFormValid) {
      const body = getBody(articleForm)
      dispatchHttp({ type: "formSent" })
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      }
      axios
        .put(apiEndPoint + "article/saveArticle", body, config)
        .then(() => navigate("/actualités"))
        .catch(() => {
          toast.error("Une erreur s'est produite, réessayer encore une fois!", {
            position: toast.POSITION.BOTTOM_CENTER,
          })
          dispatchHttp({ type: "formSendFailed" })
        })
    }
  }

  const fields = getFields(articleForm)

  return token ? (
    <Layout>
      <div className="row row-white">
        <h1 className="heading-primary text-align-left u-margin-bottom-medium">
          Ajouter un article
        </h1>
        {fields}
        <input
          disabled={formSent}
          type="submit"
          value="Sauvegarder"
          data-wait="Patientez..."
          class="button w-button button-submit u-margin-top-medium"
          onClick={formSubmittedHandler}
        />
      </div>
      <ToastContainer />
    </Layout>
  ) : null
}
export default AddArticle
