import React from "react"
import "react-quill/dist/quill.snow.css"

function Editor({ value, placeholder, inputChanged, className }) {
  if (document) {
    const ReactQuill = require('react-quill');
    return (
      <div>
        <ReactQuill
          onChange={inputChanged}
          value={value}
          modules={Editor.modules}
          formats={Editor.formats}
          bounds={".app"}
          placeholder={placeholder}
          className={className}
        />
      </div>
    )
  } else {
    return <textarea />;
  }
}

export default Editor

Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
]
